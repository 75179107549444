#section-home {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	background: $dark-green;

	.no-js & {
		background: $dark-green url('/img/home-particles.png') center;
		background-size: cover;

		.go-down {
			display: none;
		}
	}

	.tsparticles-canvas-el {
		position: absolute;
		top: 0;
		left: 0;
	}

	header {
		position: relative;
		z-index: 2;
		text-align: center;
		color: #fff;
		pointer-events: none;

		h1 {
			margin: 1rem 0 0;
			font-weight: 300;
			letter-spacing: 0.5px;
			opacity: 0;
			transform: translate3d(0, 100%, 0);
      transition: all 500ms;
		}
	}

	.logo {
		width: 150px;
		height: 150px;
		fill: #fff;

		.logo__outline {
			opacity: 0;
			transition: all 500ms 500ms;
		}

		.logo__d, .logo__h {
			opacity: 0;
			transform: translate3d(0, 300%, 0);
			transition: all 500ms;
		}
	}

	.go-down {
		display: block;
		position: absolute;
		z-index: 2;
		bottom: 1rem;
		left: 50%;
		margin-left: -1.5rem;
		transform: translateY(-50%);
		opacity: 0;
		transition: all 250ms;

		svg {
			fill: #fff;
			width: 3rem;
			height: 3rem;
			transition: all 250ms;
		}

		&:hover, &:focus {
			svg {
				fill: $green;
			}
		}
	}

  @include showing {
		.logo {
			.logo__outline {
				opacity: 1;
			}

			.logo__d, .logo__h {
				opacity: 1;
				transform: translate3d(0, 0, 0);
			}
		}

		header {
			h1 {
				opacity: 1;
				transform: translate3d(0, 0, 0);
			}
		}

		.go-down {
			opacity: 1;
			transform: translateY(0);
		}
	}
}