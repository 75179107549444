#section-contact {
	article {
		max-width: $max-content-width;
		margin: 0 auto;
		padding: 1.5rem 0 0 0;

    > p {
			text-align: center;
      font-size: 1.25rem;
			line-height: 1.5;
      opacity: 0;
      transform: translateY(100%);
      transition: all 500ms;

      @include showing {
        opacity: 1;
        transform: translateY(0);
      }
		}
		
		form {
			position: relative;
			padding: 1.5rem 0;

			&.has-alert {
				&:after {
					content: '';
					position: absolute;
					z-index: 4;
					top: 0;
					right: 0;
					bottom: 0;
					left: 0;
					background: rgba(#fff, 0.5);
				}
			}

			fieldset {
				position: relative;
				z-index: 1;
				margin: 0 0 1.5rem 0;
				padding: 0;
				border: 0;
				opacity: 0;
				transform: translateY(100%);
				transition: all 500ms;

				@include showing {
					opacity: 1;
					transform: translateY(0);
				}

				&.error {
					input, textarea {
						border-color: $red;
					}
	
					.error-tip {
						opacity: 1;
						transform: translateY(-50%);
					}
				}
	
				&.valid {
					input, textarea {
						border-color: $green;
					}
				}
			}

			label {
				display: block;
				padding: 0 0 5px 0;
				text-align: left;
				font-size: 0.9rem;
				text-transform: uppercase;
				letter-spacing: 2px;
			}
	
			input, textarea {
				width: 100%;
				padding: 1rem 1.25rem;
				border: 1px solid rgba($dark-gray, 0.5);
				border-bottom-width: 3px;
				outline: none;
				font-size: 1rem;
				transition: all 250ms;
	
				&:focus {
					border-color: $dark-gray;
				}
			}

			textarea {
				height: 15rem;
				font-family: inherit;
			}

			.error-tip {
				position: absolute;
				top: -4px;
				right: 5px;
				padding: .5rem 1rem .7rem 1rem;
				background: $red;
				opacity: 0;
				transform: translateY(100%);
				color: #fff;
				font-size: 0.9rem;
				transition: transform 250ms, opacity 250ms;
	
				&:before {
					content: '';
					position: absolute;
					bottom: -9px;
					left: 50%;
					width: 0;
					height: 0;
					transform: translate3d(-50%, 0, 0);
					border-left: 10px solid transparent;
					border-right: 10px solid transparent;
					border-top: 10px solid $red;
				}
			}

			.btn {
				display: block;
				margin: 2rem auto 0 auto;

				&:after {
					width: 5%;
				}

				&:hover {
					&:after {
						width: 20%;
					}
				}

				.btn__content {
					display: inline-block;
					transition: transform 250ms;
				}

				.btn__loading {
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%) scale(0);
					transition: all 250ms;
				}

				&.loading {
					.btn__content {
						transform: scale(0);
					}
		
					.btn__loading {
						transform: translate(-50%, -50%) scale(1);
					}
		
					&:after {
						width: 0;
					}
				}
			}

			.alert {
				position: absolute;
				z-index: 5;
				top: 50%;
				left: 50%;
				max-width: 600px;
				width: 100%;
				padding: 1.5rem;
				opacity: 0;
				transform: translate(-50%, -50%) scale(0);
				box-shadow: 0 20px 20px -10px rgba($dark-gray, 0.25);
				color: #fff;
				text-align: center;
				line-height: 1.8;
				transition: all 500ms;
	
				&.send-error {
					background: $red;
				}
	
				&.send-success {
					background: $green;
				}
	
				&.active {
					opacity: 1;
					transform: translate(-50%, -50%) scale(1);
				}
	
				.close {
					position: absolute;
					top: 0;
					right: 0;
					margin: 0;
					width: 1.75rem;
					padding: .5rem;
					background: none;
					border: 0;
					cursor: pointer;
					transition: all 250ms;
	
					&:hover {
						transform: rotate(180deg);
					}
				}
	
				p {
					margin: 0;
				}
	
				a {
					color: #fff;
	
					&:hover {
						text-decoration: none;
					}
				}
			}
		}

		.social {
			margin: 0;
			padding: 0;
			list-style: none;
			text-align: center;
	
			li {
				display: inline-block;
				margin: 0 5px;
	
				&:last-child {
					margin-right: 0;
				}
			}
	
			a {
				display: flex;
				align-items: center;
				justify-content: center;
				width: 4.5rem;
				height: 4.5rem;
				background: linear-gradient(to top, $green 50%, $dark-gray 50%);
				background-size: 100% 200%;
				border-radius: 0;
				transform: scale(0);
				transition: background-position 250ms, border-radius 250ms, transform 500ms;
				color: #fff;
				text-decoration: none;
				line-height: 1;
				font-size: 1.75rem;
	
				img {
					width: 2rem;
				}
	
				&:hover {
					background-position: 0 100%;
					border-radius: 50%;
				}
	
				@include showing {
					transform: scale(1);
				}
			}
		}

		.h-captcha {
			text-align: center;
		}
	}
}