::selection {
	background: $green;
}

html {
	font-size: 16px;

	&.smooth-scroll {
		scroll-behavior: smooth;
	}

	@media screen and (max-width: $media-small) {
		font-size: 14px;
	}
}

body {
	// System UI font catch-all
	// Chosen to avoid webfont bloat, and also because we are fine with a little font variance across devices for a site this simple
	font-family: -apple-system, BlinkMacSystemFont,
		"Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans",
		"Droid Sans", "Helvetica Neue", sans-serif;
}

.page-section {
	padding: 5rem 1rem 4rem;
	min-height: 100vh;

	> header {
		text-align: center;
	}

	.title {
		position: relative;
		display: inline-block;
		margin: 0;
		border-bottom: 3px solid $green;
		font-size: 2rem;
		font-weight: 600;
		line-height: 1.4;
		letter-spacing: .1em;
		text-transform: uppercase;
		opacity: 0;
		transform: translateY(1rem) scale(.75);
		transition: all 500ms;

		&:after {
			content: '';
			position: absolute;
			bottom: -3px;
			right: -5px;
			border-left: 10px solid transparent;
			border-bottom: 10px solid $green;
		}

		@include showing {
			opacity: 1;
			transform: translateY(0) scale(1);
		}
	}
}

#main-nav {
	position: fixed;
	display: flex;
	align-items: center;
	z-index: 10;
	top: 0;
	left: 0;
	right: 0;
	height: $nav-height;
	transform: translateZ(0);
	transition: background-color 250ms;

	.no-js & {
		display: none;
	}
	
	&.active {
		background-color: rgba(#000, 0.9);

		.logo-link {
			left: 10px;
		}

		.page-links {
			left: 70px;
		}
	}

	.logo-link {
		display: inline-block;
		position: absolute;
		top: 10px;
		left: -$nav-logo-width;
		font-size: 0;
		transition: left 250ms;

		.logo {
			width: $nav-logo-width;
			height: $nav-logo-width;
			fill: #fff;
		}
	}

	.page-links {
		position: relative;
		display: flex;
		align-items: center;
		flex: 0 1 auto;
		gap: 1rem;
		list-style: none;
		left: 20px;
		margin: -7px 0 0 0;
		padding: 0;
		transition: left 250ms;

		a {
			position: relative;
			display: inline-block;
			padding: .65rem 1rem;
			text-transform: uppercase;
			font-size: .95rem;
			font-weight: 500;
			color: #fff;
			text-decoration: none;
			transition: color 250ms;

			&:before, &:after {
				content: '';
				position: absolute;
				bottom: 0;
				left: 0;
				height: 2px;
				transition: all 250ms;
			}

			&:before {
				right: 0;
				background: rgba(#fff, 0.5);
			}

			&:after {
				width: 0;
				background: $green;
			}

			&:hover, &:focus {
				color: $green;

				&:after {
					width: 100%;
				}
			}

			&.active {
				&:before, &:after {
					height: 4px;
				}

				&:before {
					background: #fff;
				}
			}
		}
	}

	@media screen and (max-width: $media-small) {
		.logo-link {
			display: none;
		}

		.page-links {
			left: 0;
			gap: .75rem;
			margin-left: auto;
			margin-right: auto;
		}

		&.active {
			.page-links {
				left: 0;
			}
		}
	}
}

#main-footer {
	position: relative;
	overflow: hidden;
	padding: 3rem 1rem 5rem 1rem;
	background: #061002;
	text-align: center;
	color: rgba(#fff, 0.7);
	font-size: 0.9rem;

	article {
		display: flex;
		justify-content: center;
		max-width: $max-content-width;
		margin: 0 auto;
	}

	p {
		flex: 1;
		margin: 0;
		line-height: 1.5;

		a {
			color: $green;
			text-decoration: none;

			&:hover {
				text-decoration: underline;
			}
		}
	}

	p:first-of-type {
		flex: 0 0 75px;
		align-self: flex-start;
		text-align: left;
	}

	p:nth-of-type(2) {
		align-self: flex-end;
		text-align: right;
	}

	.go-top {
		position: absolute;
		left: 50%;
		bottom: 0;
		width: 4rem;
		height: 4rem;
		transform: translate(-50%, 50%) rotate(45deg);
		color: #fff;

		&:after {
			content: '';
			position: absolute;
			z-index: 1;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			background: $green;
			transition: transform 250ms;
			transform-origin: center;
		}

		svg {
			position: absolute;
			z-index: 2;
			top: 0px;
			left: 0px;
			fill: white;
			transform: rotate(135deg);
			width: 35px;
			height: 35px;
		}

		&:hover {
			&:after {
				transform: rotate(90deg);
			}
		}
	}
}

.btn {
	position: relative;
	display: inline-block;
	max-width: 300px;
	width: 100%;
	margin: 0;
	padding: 1.7rem 4rem;
	background: none;
	border: 0;
	cursor: pointer;
	overflow: hidden;
	opacity: 0;
	transform: scale(0);
	color: #fff;
	font-size: 1rem;
	font-weight: 400;
	text-decoration: none;
	text-transform: uppercase;
	letter-spacing: 2px;
	line-height: 1;
	transition: all .5s;

	@include showing {
		opacity: 1;
		transform: scale(1);
	}

	&:before {
		content: '';
		position: absolute;
		z-index: -1;
		top: 0;
		bottom: 0;
		left: 0;
		width: 300%;
		background: linear-gradient(45deg, $green 0%, #107C64 40%, $turquoise 60%, $green 100%);
		transition: all 1000ms;
	}

	&:after {
		content: '';
		position: absolute;
		top: 50%;
		left: 50%;
		width: 20%;
		height: 1px;
		margin-top: 0.9rem;
		transform: translate(-50%, 0);
		background: #fff;
		transition: all 500ms;
	}

	&:hover, &:focus {
		&:before {
			left: -100%;
		}

		&:not(.loading):after {
			width: 35%;
		}
	}
}

// screen readers only
.sr-only {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	margin: -1px;
	overflow: hidden;
	clip: rect(0,0,0,0);
	border: 0;
}