#section-work {
	background-color: #fff;
	background-image: url('/img/topography.svg');
	overflow-x: hidden;

	> header {
		margin-bottom: 2rem;
	}

	> section {
		position: relative;
		display: flex;
		max-width: $max-content-width;
		margin: 0 auto;
		background: #fff;
		border: 1px solid rgba($dark-gray, .25);
		border-bottom: 3px solid $green;
		opacity: 0;
		transition: all 500ms;

		&:nth-child(even) {
			transform: translateX(-50%);

			&:after {
				right: -1px;
				border-left: 10px solid transparent;
			}
		}

		&:nth-child(odd) {
			transform: translateX(50%);

			aside {
				order: 2;
			}

			&:after {
				left: -1px;
				border-right: 10px solid transparent;
			}
		}

		@include showing {
			opacity: 1;
			transform: translateX(0);
		}

		&:after {
      content: '';
      position: absolute;
			bottom: -1px;
			border-bottom: 10px solid $green;
    }

		&:not(:last-child) {
			margin-bottom: 1rem;
		}

		aside {
			flex-shrink: 0;
			display: flex;
			align-items: center;
			justify-content: center;

			img {
				width: 300px;
			}
		}

		article {
			> header {
				padding: 1rem 2rem .5rem 2rem;

				h3 {
					margin: 0;
					font-size: 1.4rem;
					letter-spacing: .04em;
					font-weight: 500;
				}

				h4 {
					display: flex;
					align-items: center;
					margin: .2rem 0 0;
					text-transform: uppercase;
					letter-spacing: .04em;
					color: rgba($dark-gray, .75);

					&:before {
						content: "";
						width: 20px;
						height: 20px;
						margin-right: .4rem;
						background: $green;
						border-radius: 50%;
						border: 4px solid lighten($green, 35%);
					}
				}

				.skills {
					display: flex;
					flex-wrap: wrap;
					gap: .4rem;
					margin: .7rem 0 0;
					list-style: none;
					padding: 0;
					font-size: 0;

					li {
						padding: .5rem;
						background: rgba($dark-gray, .9);
						border-left: 3px solid $green;
						font-size: .75rem;
						color: #fff;
						line-height: 1;
					}
				}
			}

			> div {
				padding: .5rem 2rem 1rem 2rem;

				p {
					margin: 0 0 1rem;

					&:last-child {
						margin-bottom: 0;
					}
				}
			}
		}

		// Specific work blocks
		&.work-ragingbull aside {
			background-color: #0c5fac;
		}
		&.work-launchfire aside {
			background-color: #154783;
		}
		&.work-vinci aside {
			background-color: #e9e9ea;
		}
		&.work-iris aside {
			background-color: #dfdfdf;
		}
		&.work-boasttech aside {
			background-color: #313332;
		}
	}

	@media screen and (max-width: $media-small) {
		> section {
			flex-direction: column;
			
			aside, &:nth-child(odd) aside {
				order: 0;
			}
		}
	}
}