// Dimensions
$max-content-width: 1000px;
$nav-height: 60px;
$nav-logo-width: 40px;

//Media
$media-small: 800px;

// Colors
$dark-gray: #333;
$green: #5AC032;
$dark-green: #061002;
$red: #c70000;
$turquoise: #107C64;