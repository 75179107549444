#section-skills {
	position: relative;
	background: $dark-green;

	.no-js & {
		background: $dark-green url('/img/skills-particles.png') center;
		background-size: cover;
	}

	.tsparticles-canvas-el {
		position: absolute;
		top: 0;
		left: 0;
	}

	header {
		position: relative;
		z-index: 2;
		color: #fff;
	}

	article {
		position: relative;
		z-index: 2;
		text-align: center;
		max-width: $max-content-width;
    margin: 0 auto;
		padding: 1.5rem 0 0;
		
		> p {
			font-size: 1.25rem;
			line-height: 1.5;
			color: #fff;
			opacity: 0;
			transform: translateY(100%);
			transition: all .5s;

			@include showing {
				opacity: 1;
				transform: translateY(0);
			}
		}

		> h3 {
			margin: 2rem 0 1rem;
			color: white;
			font-weight: 500;
			font-size: 1.25rem;
			opacity: 0;
			transform: translateY(100%);
			transition: all .5s;

			@include showing {
				opacity: 1;
				transform: translateY(0);
			}
		}

		> ul {
			display: grid;
			grid-template-columns: 1fr 1fr 1fr 1fr;
			gap: 1.5rem;
			list-style: none;
			margin: 0 0 3rem;
			padding: 0;

			li {
				position: relative;
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 1rem 2rem;
				background: linear-gradient(45deg, #efefef 0%, #f1ffeb 100%);
				border-bottom: 3px solid $green;
				color: $dark-gray;
				font-weight: 500;
				opacity: 0;
				transform: scale(0);
				transition: all 500ms;

				&:after {
					content: '';
					position: absolute;
					bottom: -3px;
					left: 0;
					border-right: 10px solid transparent;
					border-bottom: 10px solid $green;
				}

				@include showing {
					opacity: 1;
					transform: scale(1);
				}
			}

			@media screen and (max-width: $media-small) {
				grid-template-columns: 1fr 1fr;
			}
		}

		.btn {
			z-index: 2;
			margin-top: 1.5rem;
		}
	}
}