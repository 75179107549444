*,
*::before,
*::after {
	box-sizing: border-box;
}

/* Set core body defaults */
body {
	margin: 0;
	min-height: 100vh;
	text-rendering: optimizeSpeed;
	line-height: 1.5;
}

/* Make images easier to work with */
img,
picture {
	max-width: 100%;
	display: block;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
	text-decoration-skip-ink: auto;
}

/* Remove all animations and transitions for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
	*,
	*::before,
	*::after {
		animation-duration: 0.01ms !important;
		animation-iteration-count: 1 !important;
		transition-duration: 0.01ms !important;
		scroll-behavior: auto !important;
	}
}